import {
  Box,
  Button,
  Grid,
  Modal,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import moment from 'moment';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AutoComplete from '../../../../components/Form/AutoComplete';
import Checkbox from '../../../../components/Form/Checkbox';
import DatePicker from '../../../../components/Form/DatePicker';
import Input from '../../../../components/Form/Input';
import ReactSelect from '../../../../components/Form/ReactSelect';

import Select from '../../../../components/Form/Select';
import AdminTitleHeader from '../../../components/AdminTitleHeader/AdminTitleHeader';
import HorizontalMenu from '../../../components/HorizonalMenu/HorizontalMenu';
import removeIconWhite from '../../../../assets/img/icons/deleteWhite.svg';
import uploadIcon from '../../../../assets/img/icons/upload.svg';
import downIcon from '../../../../assets/img/icons/downIcon.svg';
import useAlocacaoQuadros, {
  InformationIcon,
} from '../../../hooks/useAlocacaoQuadros';
import styles from './alocacaoStyles.module.css';
import * as Yup from 'yup';
import { styled } from '@material-ui/styles';
import KeycloakService from '../../../../services/KeycloakService';
import SideMenu from '../../../components/SideMenu/SideMenu';
import { setSnackbar } from '../../../../store/reducers/admin/actionCreators';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';

const ModalContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: '#fff',
  margin: '20vh 50vh',
  borderRadius: '6px',
  // [theme.breakpoints.up("xxl")]: {
  //   height: "825px",
  // margin: "20vh 50vh",

  // },
  // [theme.breakpoints.down("xxl")]: {
  //   height: "775px",
  // margin: "20vh 50vh",

  // },
  // [theme.breakpoints.down("xl")]: {
  //   height: "675px",
  // margin: "20vh 50vh",

  // },
  // [theme.breakpoints.down("lg")]: {
  //   height: "605px",
  // margin: "20vh 50vh",

  // },
}));

const UpdateAllocationSchema = Yup.object().shape({
  employeeId: Yup.string().required('O id do quadro é obrigatório.'),
  unit: Yup.object().shape({
    name: Yup.string().required('A unidade organica é obrigatória.'),
  }),
  startedOnNew: Yup.string().test(
    'isLengthValid',
    'A data do inicio de novas funções é obrigatória.',
    (val) => {
      return val.length > 0;
    }
  ),
  // institutionOfOrigin: Yup.object().shape({
  //   id: Yup.string().required("A entidade é obrigatória."),
  // }),
  // position: Yup.object().shape({
  //   id: Yup.string().required('O cargo é obrigatório.'),
  // }),
  // category: Yup.object().shape({
  //   id: Yup.string(),
  //   name: Yup.string().when("id", {
  //     is: (id) => id === null || id === undefined || id === "",
  //     then: Yup.string().required("Deve indicar uma categoria já existente"),
  //   }),
  // }),
  // changeOfJuridicalBond: Yup.object().shape({
  //   id: Yup.string().required('A mudança da relação juridica é obrigatória.'),
  // }),
  // contractualRegimen: Yup.object().shape({
  //   id: Yup.string().required('O regime de contrato é obrigatório.'),
  // }),
  // juridicalBond: Yup.object().shape({
  //   id: Yup.string().required('A relação juridica é obrigatória.'),
  // }),
  // agentNumber: Yup.string()
  //   .required("O Nº Agente é obrigatório.")
  //   .test("isLengthValid", "O Nº de Agente tem que ter 8 dígitos.", (val) => {
  //     return val.length === 8;
  //   }),
  // ssNumber: Yup.string().required("O Nº de Seg. Social é obrigatório."),
  // cifNumber: Yup.string().required("O código de identificação é obrigatório."),
  // consultant: Yup.boolean().required("Este campo é obrigatorio"),
  // startedOn: Yup.string().test(
  //   "isLengthValid",
  //   "A data do inicio das funções é obrigatória.",
  //   (val) => {
  //     return val.length > 0;
  //   }
  // ),
});

const ProgressaoQuadros = ({ match }) => {
  const visualizeOnly = match.params.operation === 'ver';
  const formRef = useRef();
  const theme = useTheme();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState();
  const dispatch = useDispatch();

  const getUserInfo = async () => {
    const response = await KeycloakService.getUserInfo();
    const { preferred_username } = response;
    setUserInfo(preferred_username);
  };

  console.log('Operation type: ', match.params.operation);

  const [action, setAction] = useState('add');
  const [file, setFile] = useState({});
  const [fileName, setFileName] = useState('');
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [horizontalMenuOption, setHorizontalMenuOption] =
    useState('alocacao_quadros');
  const [startedOnNew, setStartedOnNew] = useState('');

  const {
    unit,
    institutionEmployees,
    currentEmployee,
    fieldsMetaData,
    fixDate,
    fixAutoComplete,
    onEmployeeClicked,
    onAddValidationComplete,
    onEditValidationComplete,
    id,
    setSideTablePageIndex,
    institutionEmployeesTotalPages,
    query,
    setQuery,
    getResourceMobilityByEmployeeId,
    getResourceMobilityInfoByEmployeeId,
    clickedEmployee,
  } = useAlocacaoQuadros(
    match.params.institutionId,
    match.params.unitId,
    match.params.employeeId,
    'progressão na carreira'
  );

  useEffect(() => {
    if (match.params.employeeId) setAction('edit');

    if (!userInfo) {
      getUserInfo();
    }
  }, [match.params, userInfo]);

  const horizontalButtons = [
    {
      code: 'alocacao_quadros',
      label: 'Progressão',
      onClick: () => setHorizontalMenuOption('alocacao_quadros'),
    },
  ];

  const handleFile = (e) => {
    let files = e.target.files[0];
    setFile({ file: files });
    setFileName(e.target.files[0].name);
  };

  const editAllocationValidation = (withFixedValues) => {
    var values = withFixedValues;

    try {
      UpdateAllocationSchema.validate(
        {
          ...values,
          employeeId: match.params.employeeId,
          unit: { name: match.params.unitId },
          // id: currentEmployee.id,
          startedOnNew,
        },
        {
          abortEarly: false,
        }
      )
        .then((submitData) => {
          console.log('Cureent: ', currentEmployee);
          console.log('Categoria', submitData.categoryId);
          console.log('Submit data: ', submitData);
          setIsLoading(true)
          axios
            .put(
              `${process.env.REACT_APP_API_URL}/resource-mobility/progress`,
              {
                startOfLeave: currentEmployee.startOfLeave,
                endOfLeave: currentEmployee.endOfLeave,
                agentNumber: submitData.agentNumber,
                ssNumber: submitData.ssNumber,
                cifNumber: submitData.cifNumber,
                consultant: currentEmployee.consultant,
                startedOn: submitData.startedOn,
                endedOn: submitData.endedOn,
                startedOnNew,
                unitId: match.params.unitId,
                id: currentEmployee.id,
                employeeId: currentEmployee.employee.id,
                changeOfJuridicalBondId: currentEmployee.changeOfJuridicalBond.id,
                contractualRegimenId: currentEmployee.contractualRegimen.id,
                juridicalBondId: currentEmployee.juridicalBond.id,
                positionId: currentEmployee?.position?.id,
                categoryId: submitData.categoryId,
                institutionOfOriginId: currentEmployee.institutionOfOrigin?.id,
                showNotification: true,
                onSuccessMessage: 'Progressão feita com sucesso',
                historicTypeId: 3,
                responsavel: userInfo,
              },
              {
                headers: {
                  Authorization: `Bearer ${KeycloakService.getToken()}`,
                },
              }
            )
            .then((response) => {
              if (!response.data) return;
              dispatch(
                setSnackbar({
                  snackbarIsOpen: true,
                  snackbarMessage: 'Progressão feita com sucesso',
                  snackbarType: 'success',
                })
              );
              history.goBack();
              // history.push(`/admin/instituicoes/${institutionId}/orgaos/${unitId}`);
            })
            .catch((error) => {
              console.log(error.response, 'Error')
              let messageError = "Houve um erro interno. Por favor, tente novamente.";

              if (error.response) {
                const details = error.response?.data?.details;
                const detailMessages = details ? details.map(detail => `- ${detail.message}`).join("\n") : "Nenhum detalhe adicional disponível.";

                messageError = `${error.response?.data?.message}\n\nDetalhes do erro:\n${detailMessages}`;
              }
              dispatch(
                setSnackbar({
                  snackbarIsOpen: true,
                  snackbarMessage: messageError,
                  snackbarType: 'error',
                })
              );
            })
            .finally(() => setIsLoading(false));
          formRef.current.setErrors({});
        })
        .catch((error) => {
          console.log(' errors:', error);
          if (error instanceof Yup.ValidationError) {
            const errorMessages = {};
            error.inner.forEach((error) => {
              console.log(error.path, error.message);
              errorMessages[error.path] = error.message;
            });
            formRef.current.setErrors(errorMessages);
          }
        })
    } catch (error) {
      console.log("Deu esse erro")
    }
  };

  const handleEmployeeAllocationSubmit = (formData, { reset }) => {
    if (visualizeOnly) return;
    var withFixedValues = fixDate(fieldsMetaData, formData);
    withFixedValues = fixAutoComplete(fieldsMetaData, formData);

    if (action === 'edit') {
      editAllocationValidation(withFixedValues);
    }
  };

  const margin = history.location.pathname.includes('admin') ? '' : '4rem 7rem';

  const ellipsisText = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
  };

  const onRowClicked = (item, status) => {
    if (status === 'ACTIVE') {
      getResourceMobilityInfoByEmployeeId(item.id);
      return setShowInfoModal(true);
    }
    onEmployeeClicked(item);
  };

  let minYearConverted;
  const splited = currentEmployee?.startedOn?.split('-');
  minYearConverted = splited?.[2] + '-' + splited?.[1] + '-' + splited?.[0];

  console.log('Current employee: ', currentEmployee);
  console.log('Current action: ', action);

  function formatDateToYYYYMMDD(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  console.log(
    formatDateToYYYYMMDD(
      'Sun Aug 11 2024 00:00:00 GMT+0100 (West Africa Standard Time)'
    )
  );
  console.log('jesus', currentEmployee);
  console.log('cleu', fieldsMetaData);
  return (
    <Box sx={{ heigh: '100vh', display: 'flex' }}>
      <SideMenu />
      <Box
        sx={{ display: 'flex', flexDirection: 'column', padding: '25px 50px' }}
        style={{ margin }}
      >
        <AdminTitleHeader title={'Progressão na carreira'} />
        <Box
          sx={{
            backgroundColor: '#fff',
            border: '1px solid #dfdfdf',
            borderBottom: '2px solid #dfdfdf',
            padding: '1rem 3rem',
            display: 'flex',
          }}
        >
          <Box sx={{ width: '50%', display: 'flex', gap: '2rem' }}>
            <Box
              sx={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'flex-end',
                color: theme.palette.primary.dark,
                fontWeight: 'bold',
              }}
            >
              <span>Entidade</span>
              <span>Data de constituição</span>
            </Box>
            <Box
              sx={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'flex-start',
                color: '#3B4144',
              }}
            >
              <Tooltip title={unit?.institution?.name} placement='top'>
                <span style={ellipsisText}>{unit?.institution?.name}</span>
              </Tooltip>
              <Tooltip
                title={unit?.institution?.constitutionDate}
                placement='top-start'
              >
                <span style={ellipsisText}>
                  {moment(unit?.institution?.constitutionDate).format(
                    'DD-MM-yyyy'
                  )}
                </span>
              </Tooltip>
            </Box>
          </Box>

          <Box sx={{ width: '50%', display: 'flex', gap: '2rem' }}>
            <Box
              sx={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'flex-end',
                color: theme.palette.primary.dark,
                fontWeight: 'bold',
              }}
            >
              <span>Orgão</span>
              <span>Pelouro</span>
            </Box>
            <Box
              sx={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'flex-start',
                color: '#3B4144',
              }}
            >
              <Tooltip title={unit?.name} placement='top'>
                <span style={ellipsisText}>{unit?.name}</span>
              </Tooltip>
              <Tooltip title={unit?.expertiseName} placement='top'>
                <span style={ellipsisText}>{unit?.expertiseName}</span>
              </Tooltip>
            </Box>
          </Box>
        </Box>

        <HorizontalMenu
          currentOption={horizontalMenuOption}
          options={horizontalButtons}
        />

        {/* PAGE CONTENT */}
        <Box
          sx={{
            backgroundColor: '#fff',
            padding: { xs: '2rem 1rem', md: '2rem 1.5rem' },
            display: 'flex',
          }}
        >
          <Box
            sx={{
              flexBasis: `100%`,
            }}
          >
            <Form
              ref={formRef}
              onSubmit={handleEmployeeAllocationSubmit}
              initialData={currentEmployee}
              style={{ borderRadius: 'unset', boxShadow: 'none', padding: '0' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: { xs: 'column wrap', md: 'row wrap' },
                  rowGap: '12px',
                  padding: 0,
                  margin: 0,
                }}
              >
                {fieldsMetaData.map((meta, i) => {
                  return meta?.title ? (
                    <Typography
                      variant='h4'
                      color='primary'
                      gutterBottom
                      sx={{
                        width: '100%',
                        fontWeight: 'bold',
                      }}
                    >
                      {!['Licença', 'Enquadramento'].includes(meta.title) ? meta.title : null}
                    </Typography>
                  ) : (
                    <>
                      <Box
                        sx={{
                          width: { xs: '100%', md: '50%' },
                          rowGap: '1rem',
                          display: 'flex',
                          flexFlow: 'column wrap',
                          flex: 1,
                        }}
                      // style={{ width: '50%' }}
                      >
                        {![
                          'select',
                          'checkbox',
                          'none',
                          'date',
                          'file',
                          'auto-complete',
                          'entity',
                        ].includes(meta.type) && (
                            <Input
                              key={i}
                              type={meta.type}
                              name={meta.field}
                              label={meta.label}
                              onChange={meta.onChange}
                              isMandatory={meta.isMandatory}
                              style={{ marginRight: 8, width: '87%' }}
                            />
                          )}

                        {meta.field !== 'startOfLeave' &&
                          meta.field !== 'endOfLeave' &&
                          meta.type === 'date' && (
                            <DatePicker
                              key={i}
                              type={meta.type}
                              name={meta.field}
                              label={meta.label}
                              withHours={meta.withHours}
                              dateFormat={meta.dateFormat}
                              placeholder={meta.placeholder}
                              isMandatory={meta.isMandatory}
                              notEndDate={false}
                              minYears={moment(
                                unit?.institution?.constitutionDate
                              ).format('DD-MM-yyyy')}
                              style={{ width: '100%' }}
                            />
                          )}

                        {meta.type === 'auto-complete' && (
                          <div style={{ marginTop: '-2px' }}>
                            <AutoComplete
                              showModal={true}
                              Select={Select}
                              key={i}
                              type={meta.type}
                              split={meta.split}
                              name={meta.field}
                              label={meta.label}
                              disabled={meta.disabled}
                              optionscode={meta.optionscode}
                              splitOptionsCodes={meta.splitOptionsCodes}
                              placeholder={meta.placeholder}
                              isMandatory={meta.isMandatory}
                            />
                          </div>
                        )}


                        {/* {meta.type === 'select' &&
                          meta.field !== 'institutionOfOrigin.id' && (
                            <Select
                              key={i}
                              name={meta.field}
                              label={meta.label}
                              options={meta.options}
                              onChange={meta.onChange}
                              isMandatory={meta.isMandatory}
                              style={{ width: '97%' }}
                            />
                          )} */}

                        {/* {meta.type === 'file' && (
                          <Input
                            type='file'
                            name='files'
                            // label={meta.label}
                            onChange={(e) => handleFile(e)}
                            style={{ widht: '100%' }}
                          />
                        )} */}

                        {/* {meta.type === 'checkbox' && (
                          <Checkbox
                            key={i}
                            name={meta.field}
                            label={meta.label}
                            // disabled={meta.disabled}
                            isMandatory={meta.isMandatory}
                            styles={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                            }}
                            style={{
                              width: '15px',
                              height: '15px',
                            }}
                            // left={true}
                          />
                        )} */}
                      </Box>
                    </>
                  );
                })}
                <div>
                  <DatePicker
                    type='date'
                    name='startedOnNew'
                    label={
                      <InformationIcon label={'inicio de nova categoria'} />
                    }
                    dateFormat='dd-MM-yyyy'
                    isMandatory={true}
                    style={{ width: '100%' }}
                    onChange={(date) => {
                      const formattedDate = formatDateToYYYYMMDD(date);
                      setStartedOnNew(formattedDate);
                    }}
                    value={startedOnNew}
                  />
                </div>
              </Box>
              {!['ver'].includes(match.params.operation) && (
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      gap: '1rem',
                      marginTop: 7,
                    }}
                  >
                    <>
                      <Button
                        variant='outlined'
                        type='cancel'
                        onClick={(e) => {
                          e.preventDefault();
                          setAction(null);
                          history.goBack();
                        }}
                        disabled={isLoading}
                      >
                        Cancelar
                      </Button>
                      <LoadingButton loading={isLoading} variant="contained" type='submit'>
                        Salvar
                      </LoadingButton>
                    </>
                  </Box>
                </Box>
              )}
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProgressaoQuadros;

import {
  Box,
  Button,
  Grid,
  Modal,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import moment from 'moment';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AutoComplete from '../../../../components/Form/AutoComplete';
import Checkbox from '../../../../components/Form/Checkbox';
import DatePicker from '../../../../components/Form/DatePicker';
import Input from '../../../../components/Form/Input';
import ReactSelect from '../../../../components/Form/ReactSelect';

import Select from '../../../../components/Form/Select';
import AdminTitleHeader from '../../../components/AdminTitleHeader/AdminTitleHeader';
import HorizontalMenu from '../../../components/HorizonalMenu/HorizontalMenu';
import removeIconWhite from '../../../../assets/img/icons/deleteWhite.svg';
import uploadIcon from '../../../../assets/img/icons/upload.svg';
import downIcon from '../../../../assets/img/icons/downIcon.svg';
import useAlocacaoQuadros from '../../../hooks/useAlocacaoQuadros';
import styles from './alocacaoStyles.module.css';
import * as Yup from 'yup';
import { styled } from '@material-ui/styles';
import KeycloakService from '../../../../services/KeycloakService';
import SideMenu from '../../../components/SideMenu/SideMenu';
import { setSnackbar } from '../../../../store/reducers/admin/actionCreators';
import { useDispatch } from 'react-redux';
import axios from 'axios';

const ModalContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: '#fff',
  margin: '20vh 50vh',
  borderRadius: '6px',
  // [theme.breakpoints.up("xxl")]: {
  //   height: "825px",
  // margin: "20vh 50vh",

  // },
  // [theme.breakpoints.down("xxl")]: {
  //   height: "775px",
  // margin: "20vh 50vh",

  // },
  // [theme.breakpoints.down("xl")]: {
  //   height: "675px",
  // margin: "20vh 50vh",

  // },
  // [theme.breakpoints.down("lg")]: {
  //   height: "605px",
  // margin: "20vh 50vh",

  // },
}));

const UpdateAllocationSchema = Yup.object().shape({
  employeeId: Yup.string().required('O id do quadro é obrigatório.'),
  unit: Yup.object().shape({
    name: Yup.string().required('A unidade organica é obrigatória.'),
  }),
  startedOnNew: Yup.string().test(
    'isLengthValid',
    'A data do inicio de novas funções é obrigatória.',
    (val) => {
      return val.length > 0;
    }
  ),
  // institutionOfOrigin: Yup.object().shape({
  //   id: Yup.string().required("A entidade é obrigatória."),
  // }),
  // position: Yup.object().shape({
  //   id: Yup.string().required("O cargo é obrigatório."),
  // }),
  // category: Yup.object().shape({
  //   id: Yup.string(),
  //   name: Yup.string().when("id", {
  //     is: (id) => id === null || id === undefined || id === "",
  //     then: Yup.string().required("Deve indicar uma categoria já existente"),
  //   }),
  // }),
  // changeOfJuridicalBond: Yup.object().shape({
  //   id: Yup.string().required("A mudança da relação juridica é obrigatória."),
  // }),
  // contractualRegimen: Yup.object().shape({
  //   id: Yup.string().required("O regime de contrato é obrigatório."),
  // }),
  // juridicalBond: Yup.object().shape({
  //   id: Yup.string().required("A relação juridica é obrigatória."),
  // }),
  // agentNumber: Yup.string()
  //   .required("O Nº Agente é obrigatório.")
  //   .test("isLengthValid", "O Nº de Agente tem que ter 8 dígitos.", (val) => {
  //     return val.length === 8;
  //   }),
  // ssNumber: Yup.string().required("O Nº de Seg. Social é obrigatório."),
  // cifNumber: Yup.string().required("O código de identificação é obrigatório."),
  // consultant: Yup.boolean().required("Este campo é obrigatorio"),
  // startedOn: Yup.string().test(
  //   "isLengthValid",
  //   "A data do inicio das funções é obrigatória.",
  //   (val) => {
  //     return val.length > 0;
  //   }
  // ),
});

const NomeacaoQuadros = ({ match }) => {
  const visualizeOnly = match.params.operation === 'ver';
  const formRef = useRef();
  const theme = useTheme();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState();
  const dispatch = useDispatch();

  const getUserInfo = async () => {
    const response = await KeycloakService.getUserInfo();
    const { preferred_username } = response;
    setUserInfo(preferred_username);
  };

  console.log('Operation type: ', match.params.operation);

  const [action, setAction] = useState('add');
  const [file, setFile] = useState({});
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [horizontalMenuOption, setHorizontalMenuOption] =
    useState('alocacao_quadros');
  const [startedOnNew, setStartedOnNew] = useState('');

  const {
    unit,
    institutionEmployees,
    currentEmployee,
    fieldsMetaData,
    fixDate,
    fixAutoComplete,
    onEmployeeClicked,
    onAddValidationComplete,
    onEditValidationComplete,
    id,
    setSideTablePageIndex,
    institutionEmployeesTotalPages,
    query,
    setQuery,
    getResourceMobilityByEmployeeId,
    getResourceMobilityInfoByEmployeeId,
    clickedEmployee,
  } = useAlocacaoQuadros(
    match.params.institutionId,
    match.params.unitId,
    match.params.employeeId,
    'exonom'
  );

  useEffect(() => {
    if (match.params.employeeId) setAction('edit');

    if (!userInfo) {
      getUserInfo();
    }
  }, [match.params, userInfo]);

  const getHistoricType = (type) => {
    switch (type) {
      case 'mobilidade':
        return 1;
      case 'exonom':
        return 2;
      case 'progresso':
        return 3;
      case 'editar':
        return 4;
    }
  };

  const horizontalButtons = [
    {
      code: 'alocacao_quadros',
      label: 'Nomeação',
      onClick: () => setHorizontalMenuOption('alocacao_quadros'),
    },
  ];

  const editAllocationValidation = (withFixedValues) => {
    var values = withFixedValues;

    console.log('Employee: ', currentEmployee);
    UpdateAllocationSchema.validate(
      {
        ...values,
        employeeId: match.params.employeeId,
        unit: { name: match.params.unitId },
        id: currentEmployee.id,
        startedOnNew,
      },
      {
        abortEarly: false,
      }
    )
      .then((submitData) => {
        console.log('Cureent: ', currentEmployee.employee.id);
        console.log('Submit Data: ', submitData);

        const historicTypeId = getHistoricType('editar');

        //const dataActivity = JSON.parse(localStorage.getItem('userData'));

        axios
          .put(
            `${process.env.REACT_APP_API_URL}/resource-mobility/update`,
            {
              id: currentEmployee.id,
              agentNumber: currentEmployee.agentNumber,
              ssNumber: currentEmployee.ssNumber,
              cifNumber: currentEmployee.cifNumber,
              consultant: currentEmployee.consultant,
              startedOn: currentEmployee.startedOn,
              startedOnNew,
              endedOn: currentEmployee.endedOn,
              unitId: match.params.unitId,
              employeeId: currentEmployee.employee.id,
              changeOfJuridicalBondId: currentEmployee.changeOfJuridicalBond.id,
              contractualRegimenId: currentEmployee.contractualRegimen.id,
              juridicalBondId: currentEmployee.juridicalBond.id,
              positionId: submitData.position.id,
              categoryId: currentEmployee.category.id,
              institutionOfOriginId: currentEmployee.institutionOfOrigin?.id,
              showNotification: true,
              onSuccessMessage: 'Nomeação feita com sucesso',
              historicTypeId: 5,
              responsavel: userInfo,
              //logsContaId: dataActivity.userId || null
            },
            {
              headers: {
                Authorization: `Bearer ${KeycloakService.getToken()}`,
              },
            }
          )
          .then((response) => {
            if (!response.data) return;
            dispatch(
              setSnackbar({
                snackbarIsOpen: true,
                snackbarMessage: 'Nomeação feita com sucesso',
                snackbarType: 'success',
              })
            );
            history.goBack();
            // history.push(`/admin/instituicoes/${institutionId}/orgaos/${unitId}`);
          })
          .catch((error) => {
            console.log(error, 'error catch');
            let messageError = "Houve um erro interno. Por favor, tente novamente.";

            if (error.message) messageError = error.message;

            if (error.response) {
              const details = error.response?.data?.details;
              const detailMessages = details ? details.map(detail => `- ${detail.message}`).join("\n") : "Nenhum detalhe adicional disponível.";

              messageError = `${error.response?.data?.message}\n\nDetalhes do erro:\n${detailMessages}`;
            }
            dispatch(
              setSnackbar({
                snackbarIsOpen: true,
                snackbarMessage: messageError,
                snackbarType: 'error',
              })
            );
          });

        formRef.current.setErrors({});
      })
      .catch((error) => {
        console.log(' errors:', error);
        if (error instanceof Yup.ValidationError) {
          const errorMessages = {};
          error.inner.forEach((error) => {
            console.log(error.path, error.message);
            errorMessages[error.path] = error.message;
          });
          formRef.current.setErrors(errorMessages);
        }
      });
  };

  const handleEmployeeAllocationSubmit = (formData, { reset }) => {
    if (visualizeOnly) return;
    var withFixedValues = fixDate(fieldsMetaData, formData);
    withFixedValues = fixAutoComplete(fieldsMetaData, formData);

    if (action === 'edit') {
      editAllocationValidation(withFixedValues);
    }
  };

  const margin = history.location.pathname.includes('admin') ? '' : '4rem 7rem';

  const ellipsisText = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
  };

  const onRowClicked = (item, status) => {
    if (status === 'ACTIVE') {
      getResourceMobilityInfoByEmployeeId(item.id);
      return setShowInfoModal(true);
    }
    onEmployeeClicked(item);
  };

  let minYearConverted;
  const splited = currentEmployee?.startedOn?.split('-');
  minYearConverted = splited?.[2] + '-' + splited?.[1] + '-' + splited?.[0];

  console.log('Current employee: ', currentEmployee);
  console.log('Current action: ', action);

  function formatDateToYYYYMMDD(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  console.log(
    formatDateToYYYYMMDD(
      'Sun Aug 11 2024 00:00:00 GMT+0100 (West Africa Standard Time)'
    )
  );

  return (
    <Box sx={{ heigh: '100vh', display: 'flex' }}>
      <SideMenu />
      <Box
        sx={{ display: 'flex', flexDirection: 'column', padding: '25px 50px' }}
        style={{ margin }}
      >
        <AdminTitleHeader title={'Nomeação de Quadros'} />
        <Box
          sx={{
            backgroundColor: '#fff',
            border: '1px solid #dfdfdf',
            borderBottom: '2px solid #dfdfdf',
            padding: '1rem 3rem',
            display: 'flex',
          }}
        >
          <Box sx={{ width: '50%', display: 'flex', gap: '2rem' }}>
            <Box
              sx={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'flex-end',
                color: theme.palette.primary.dark,
                fontWeight: 'bold',
              }}
            >
              <span>Entidade</span>
              <span>Data de constituição</span>
            </Box>
            <Box
              sx={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'flex-start',
                color: '#3B4144',
              }}
            >
              <Tooltip title={unit?.institution?.name} placement='top'>
                <span style={ellipsisText}>{unit?.institution?.name}</span>
              </Tooltip>
              <Tooltip
                title={unit?.institution?.constitutionDate}
                placement='top-start'
              >
                <span style={ellipsisText}>
                  {moment(unit?.institution?.constitutionDate).format(
                    'DD-MM-yyyy'
                  )}
                </span>
              </Tooltip>
            </Box>
          </Box>

          <Box sx={{ width: '50%', display: 'flex', gap: '2rem' }}>
            <Box
              sx={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'flex-end',
                color: theme.palette.primary.dark,
                fontWeight: 'bold',
              }}
            >
              <span>Orgão</span>
              <span>Pelouro</span>
            </Box>
            <Box
              sx={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'flex-start',
                color: '#3B4144',
              }}
            >
              <Tooltip title={unit?.name} placement='top'>
                <span style={ellipsisText}>{unit?.name}</span>
              </Tooltip>
              <Tooltip title={unit?.expertiseName} placement='top'>
                <span style={ellipsisText}>{unit?.expertiseName}</span>
              </Tooltip>
            </Box>
          </Box>
        </Box>

        <HorizontalMenu
          currentOption={horizontalMenuOption}
          options={horizontalButtons}
        />

        {/* PAGE CONTENT */}
        <Box
          sx={{
            backgroundColor: '#fff',
            padding: '2rem 1.5rem',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              flexBasis: `100%`,
            }}
          >
            <Form
              ref={formRef}
              onSubmit={handleEmployeeAllocationSubmit}
              initialData={currentEmployee}
              style={{ borderRadius: 'unset', boxShadow: 'none', padding: '0' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: 'row wrap',
                  rowGap: '1rem',
                  padding: 0,
                  margin: 0,
                }}
              >
                {fieldsMetaData.map((meta, i) => {
                  return meta?.title ? (
                    <Typography
                      variant='h4'
                      color='primary'
                      gutterBottom
                      sx={{
                        width: '100%',
                        fontWeight: 'bold',
                      }}
                    >
                      {!['Licença', 'Enquadramento'].includes(meta.title) ? meta.title : null}
                    </Typography>
                  ) : (
                    <Box>
                      {![
                        'select',
                        'checkbox',
                        'none',
                        'date',
                        'file',
                        'auto-complete',
                        'entity',
                      ].includes(meta.type) && (
                          <Input
                            key={i}
                            type={meta.type}
                            name={meta.field}
                            label={meta.label}
                            disabled={true}
                            onChange={meta.onChange}
                            isMandatory={meta.isMandatory}
                            style={{ marginRight: 8 }}
                          />
                        )}
                      <Box>
                        {meta.field === 'startedOn' && meta.type == 'date' && (
                          <DatePicker
                            key={i}
                            type={meta.type}
                            name={meta.field}
                            label={meta.label}
                            withHours={meta.withHours}
                            dateFormat={meta.dateFormat}
                            placeholder={meta.placeholder}
                            isMandatory={meta.isMandatory}
                            notEndDate={false}
                            minYears={moment(
                              unit?.institution?.constitutionDate
                            ).format('DD-MM-yyyy')}
                            style={{ marginRight: 8 }}
                          />
                        )}
                        {['position.id'].includes(meta.field)
                          ? meta.type == 'select' && (
                            <Select
                              key={i}
                              name={meta.field}
                              label={meta.label}
                              options={meta.options}
                              onChange={meta.onChange}
                              isMandatory={meta.isMandatory}
                            />
                          )
                          : null}
                        {['position.id'].includes(meta.field)
                          ? meta.type == 'entity' && (
                            <ReactSelect
                              key={i}
                              name={meta.field}
                              label={meta.label}
                              options={meta.options}
                              onChange={meta.onChange}
                              isMandatory={meta.isMandatory}
                            />
                          )
                          : null}
                      </Box>
                    </Box>
                  );
                })}
                <div style={{ display: 'flex', gap: '8px' }}>
                  <DatePicker
                    type='date'
                    name='startedOnNew'
                    label='Início de novas funções'
                    dateFormat='dd-MM-yyyy'
                    isMandatory={true}
                    style={{ width: '100%' }}
                    minDate={new Date()}
                    onChange={(date) => {
                      const formattedDate = formatDateToYYYYMMDD(date);
                      setStartedOnNew(formattedDate);
                    }}
                    value={startedOnNew}
                  />
                </div>
              </Box>
              {!['ver'].includes(match.params.operation) && (
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      gap: '1rem',
                      marginTop: 9,
                    }}
                  >
                    <>
                      <Button
                        variant='outlined'
                        type='cancel'
                        onClick={(e) => {
                          e.preventDefault();
                          setAction(null);
                          history.goBack();
                        }}
                      >
                        Cancelar
                      </Button>
                      <Button type='submit' variant='contained'>
                        Nomear
                      </Button>
                    </>
                  </Box>
                </Box>
              )}
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NomeacaoQuadros;
